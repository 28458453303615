import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import OrigoAppShell from "@oriola-origo/origo-app-shell";

const ensureNoTrailingSlash = str =>
  str.endsWith("/") ? str.slice(0, -1) : str;

function Layout({ children }) {
  const { tokenData } = useSelector(state => state.user);
  return (
    <OrigoAppShell
      origoAccessToken={tokenData.accessToken}
      origoUrl={ensureNoTrailingSlash(process.env.REACT_APP_ORIGO_URL)}
      activeApplicationId={process.env.REACT_APP_ORIGO_APP_ID}
      queryParameters={window.location.search}
    >
      {children}
    </OrigoAppShell>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
